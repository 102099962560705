var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"bg-google d-none d-lg-flex align-items-center px-5",style:({
        backgroundImage: ("url('" + _vm.imgUrl + "')"),
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
      }),attrs:{"lg":"8"}},[_c('b-link',{staticClass:"brand-logo d-none d-lg-flex"},[_c('b-img',{staticClass:"flat-image",staticStyle:{"width":"100px"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}}),_c('h2',{staticClass:"brand-text text-primary ml-50 mt-auto mb-auto"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.appName))])])],1),_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"})],1),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-link',{staticClass:"brand-logo d-flex d-lg-none"},[_c('b-img',{staticStyle:{"width":"36px"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}}),_c('h2',{staticClass:"brand-text text-primary ml-50 mt-auto mb-auto"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])],1),_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Daftar ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Bergabung menjadi partner "),_c('strong',[_vm._v("Komerce")])]),_c('b-alert',{attrs:{"variant":"danger","show":!!_vm.error}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.error))])])]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":"Nama lengkap","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nama lengkap","vid":"email","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 || _vm.submitErrors.name ? false:null,"name":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Nama lengkap","vid":"email","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 || _vm.submitErrors.username ? false:null,"name":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.username))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 || _vm.submitErrors.email ? false:null,"name":"login-email"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.email))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 || _vm.submitErrors.password ? false:null,"type":_vm.passwordFieldType,"name":"login-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.password))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Daftar ")],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }